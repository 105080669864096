import React, { Component } from 'react';
import { Route, Link} from 'react-router-dom';
import logo from './logo.svg';
import { library } from '../node_modules/@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '../node_modules/@fortawesome/react-fontawesome';
import { faBars } from '../node_modules/@fortawesome/free-solid-svg-icons';
import { faBolt } from '../node_modules/@fortawesome/free-solid-svg-icons';
import { faRocket } from '../node_modules/@fortawesome/free-solid-svg-icons';
import { faCog } from '../node_modules/@fortawesome/free-solid-svg-icons';
import { faDatabase } from '../node_modules/@fortawesome/free-solid-svg-icons';
import ScrollableAnchor from 'react-scrollable-anchor';
import ScrollAnimation from 'react-animate-on-scroll';

import Fade from 'react-reveal/Fade';


const tech_progress = {
  width: '0%'
};

const high_div = {
  height:'2000px'
};

const portfolio_images = [
  {
    name: 'InvestmentNews',
    imageURL: 'images/investmentnews.png',    
    tags: 'JavaScript, jQuery, PHP, WordPress, SASS',
    link: 'https://www.investmentnews.com',
    id: 1  
  },
  {
    name: 'Unigo',
    imageURL: 'images/unigo_college_wide.jpg',    
    tags: 'C# MVC, JavaScript, jQuery, Angular, SASS',
    link: 'https://www.unigo.com',
    id: 2  
  },
  {
    name: 'eStudentLoan',
    imageURL: 'images/eStudentLoan_Landscape.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'https://www.estudentloan.com',
    id: 3
  },
  {
    name: 'Advantage Care Physicians',
    imageURL: 'images/ACPNY_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'http://www.acpny.com',
    id: 4
  },
  {
    name: 'Houston Grand Opera',
    imageURL: 'images/HGO_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'https://www.houstongrandopera.org',
    id: 5
  },
  {
    name: 'Eating Recovery Center',
    imageURL: 'images/ERC_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'https://www.eatingrecoverycenter.com',
    id: 6
  },
  {
    name: 'ADP',
    imageURL: 'images/ADP_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'https://www.adp.com',
    id: 7
  },
  {
    name: 'GoHawaii',
    imageURL: 'images/GoHawaii_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'http://agents.gohawaii.com',
    id: 8
  },
  {
    name: 'Northstar Marketing Solutions',
    imageURL: 'images/Outrigger_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'http://outrigger.northstarmarketingsolutions.com/',
    id: 9
  },
  {
    name: 'Wasab.io',
    imageURL: 'images/Wasab_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'http://wasab.io',
    id: 10
  },
  {
    name: 'ELS',
    imageURL: 'images/ELS_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'https://www.els.edu/',
    id: 11
  },
  {
    name: 'Aetrex Academy',
    imageURL: 'images/Aetrex_Academy_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'http://aetrexacademy.com',
    id: 12
  },
  {
    name: 'Aetrex',
    imageURL: 'images/Aetrex_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'http://www.aetrex.com',
    id: 13
  },
  {
    name: 'iStep',
    imageURL: 'images/iStep_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'http://www.i-step.com',
    id: 14
  },
  {
    name: 'iStep To Go',
    imageURL: 'images/iStep_ToGo_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'http://dealerform.aetrexmarketing.com/for_jeff/istep-to-go/index28a_black.php',
    id: 15
  },
  {
    name: 'Aetrex Willowbrook',
    imageURL: 'images/Aetrex_Willowbrook_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'http://aetrex.net/willowbrook_store/',
    id: 16
  },
  {
    name: 'Spinblox',
    imageURL: 'images/SpinBlox_Wide.png',    
    tags: 'Drupal, PHP, JavaScript, jQuery, CSS3',
    link: 'http://www.jeffpaitchell.com/work_area/spinblox/index.html',
    id: 17
  },
  {
    name: 'JPTV Media',
    imageURL: 'images/JPTV_Wide.png',    
    tags: 'C# MVC, JavaScript, SASS',
    link: 'http://www.jptvmedia.com/',
    id: 18

  }  
];

const mappingFunction = p => p.name;
const imageURLs = portfolio_images.map(p => p.imageURL);

class NavItem extends Component{ 

     toggleClass = ()  => { 
       this.props.toggleClass(this.props.text);
     };

    render(){
        return(
                <a 
                    href={ this.props.href } 
                    className={'nav-link nav-menu-item ' + (this.props.active ? 'menu-active' : '') } 
                    onClick={this.toggleClass}>{ this.props.text }</a>                
            )
    }

}

class NavComponent extends Component {

constructor(props) {
       super(props);

       this.state = {
          navigation: '',
       };
    }

toggleClass = (items) => {
       this.setState(prevState => ({ navigation: prevState.navigation === items? '': items }));
    };

  render() {
        return (
          <nav className="navbar navbar-expand-lg bg-dark fixed-top">          
          <a className="navbar-brand" href="#home">Jeff Paitchell</a>
            <div className="navWide">            
              <div className="wideDiv">
              <ul>                
                <li className="nav-item"><NavItem href='#about' text='About' toggleClass={this.toggleClass} active={this.state.navigation === 'About'}/></li>                
                <li className="nav-item"><NavItem href='#technologies' text='Technologies' toggleClass={this.toggleClass} active={this.state.navigation === 'Technologies'}/></li>
                <li className="nav-item"><NavItem href='#portfolio' text='Portfolio' toggleClass={this.toggleClass} active={this.state.navigation === 'Portfolio'}/></li>
                <li className="nav-item"><NavItem href='#contact' text='Contact' toggleClass={this.toggleClass} active={this.state.navigation === 'Contact'}/></li>
              </ul>
              </div>
            </div>            
            <div className="navNarrow">
              <div className="mobile-button-container">                
                <FontAwesomeIcon icon={faBars} onClick={this.burgerToggle} />
              </div>              
              <ul className="narrowLinks">                
                <li className="nav-item-mobile"><NavItem href='#about' text='About' toggleClass={this.toggleClass} active={this.state.navigation === 'About'}/></li>
                <li className="nav-item-mobile"><NavItem href='#technologies' text='Technologies' toggleClass={this.toggleClass} active={this.state.navigation === 'Technologies'}/></li>
                <li className="nav-item-mobile"><NavItem href='#portfolio' text='Portfolio' toggleClass={this.toggleClass} active={this.state.navigation === 'Portfolio'}/></li>
                <li className="nav-item-mobile"><NavItem href='#contact' text='Contact' toggleClass={this.toggleClass} active={this.state.navigation === 'Contact'}/></li>
              </ul>
            </div>            
          </nav>
        );
      }
    burgerToggle = () => {
        let linksEl = document.querySelector('.narrowLinks');
        if (linksEl.style.display === 'block') {
          linksEl.style.display = 'none';
        } else {
          linksEl.style.display = 'block';
        }
      };
    }

function Home () {
  return (
  <div className="main-wrapper">  
    <div className="home-background">
      <div className="col-md-10 home_column">
          <h1>Jeff Paitchell</h1>
          <h2>Full Stack Web Developer</h2>
          <p>Hoboken, New Jersey</p>
      </div>
    </div>

    <ScrollableAnchor id={'home'}>
        <span>&nbsp;</span>                          
      </ScrollableAnchor>  

    <div className="About-container">
      <ScrollableAnchor id={'about'}>
        <span>&nbsp;</span>                          
      </ScrollableAnchor>
      <div className="div_spacer_about"></div>

      <h2>About Me:</h2>

      <div className="row">                

        <About />

        <div className="col-md-6 col-sm-10 About-Profile-Container">
              <div className="About-Profile-Picure-Container">
                  <img src="images/jeff_paitchell_profile.jpeg" />
                  <p className="key_point">As a full stack developer, I am proficient working with both front-end 
                  and back-end web technologies to create rich and powerful web applications.</p>
              </div>      
        </div>

        <ScrollableAnchor id={'technologies'}>
            <Progress_Techs />
        </ScrollableAnchor>
      </div>  

      <ScrollableAnchor id={'portfolio'}>
        <ImageGallery />
      </ScrollableAnchor>

      <ScrollableAnchor id={'contact'}>
        <span>&nbsp;</span>
      </ScrollableAnchor>

      <div className="div_spacer_normal"></div>

      <h2>Contact</h2>
      <p className="contact-message">For more information, please contact me at <a href="mailto:jpaitchell@gmail.com">jpaitchell@gmail.com</a></p>

    </div> 
  </div> 
  );
}

function checkProgTechsVisible(elm) {
  var rect = elm.getBoundingClientRect();
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

class ImageGallery extends Component {
  render() {  
        return (
              <div>  
                <div className="Portfolio-Header">
                    <div className="div_spacer_normal"></div>
                    <a href='#section3'></a>
                    <h2>Portfolio</h2>
                </div>
                <div className="Image-Gallery-Container">                                  
                      {portfolio_images.map(p => 
                        <div key={p.id} className="col-md-4 col-sm-10 Image-Gallery-Image-Portfolio">
                          <a href={p.link} target="_blank">
                            <img alt={p.name} src={p.imageURL} />
                            <div className="portfolio_caption">
                              <span>{p.name}</span>
                            </div>
                          </a>
                        </div>  )}                        
                 </div>
              </div> 
            );
      }
  }

class About extends Component {
  render() {
    return (
    <div className="col-sm-12 About-Wrapper">
    <div className="row">            
        <a href='#section1'></a>
        <Fade left>
        <div className="col-12 col-sm-6 col-md-3 float-left">          
            <div className="About-Points">
              <FontAwesomeIcon icon={faBolt} />            
            </div>          
          <div className="About-Info">  
            <h3>Responsive</h3>
            <p className="key_point">Creating websites and applications that are responsive across multiple platforms.</p>
          </div>
        </div>
        </Fade>
        <Fade left>
        <div className="col-12 col-sm-6 col-md-3 float-left">
          <div className="About-Points">
            <FontAwesomeIcon icon={faRocket} />
          </div>
          <div className="About-Info">  
            <h3>Speed</h3>
            <p className="key_point">Utlilizing optimal techniques for fast load times and interactions.</p>
          </div>
        </div>
        </Fade>
        <Fade left>  
        <div className="col-12 col-sm-6 col-md-3 float-left">
          <div className="About-Points">
            <FontAwesomeIcon icon={faCog} />
          </div>
          <div className="About-Info">  
            <h3>Integration</h3>
            <p className="key_point">Incorporating APIs to retrieve information for your data-rich needs.</p>
          </div>
        </div>
        </Fade>
        <Fade left>
        <div className="col-12 col-sm-6 col-md-3 float-left">
          <div className="About-Points">
            <FontAwesomeIcon icon={faDatabase} />
          </div>
          <div className="About-Info">  
            <h3>Robust</h3>
            <p className="key_point">Including full-stack technlogies to enhance web application capabilities.</p>
          </div>
        </div>
        </Fade>
     </div> 
    </div>
    );
  }
}

class Progress_Techs extends Component {
  render() {

    // Add 'onscroll' event to check if the element is 
    window.onscroll = function() {checkIfProgTechsIsVisible()};

    function checkIfProgTechsIsVisible() {

      let javascript_progress_bar = document.getElementById("javascript_progress_bar");
      let php_progress_bar = document.getElementById("php_progress_bar");
      let csharp_progress_bar = document.getElementById("csharp_progress_bar");
      let dotnet_progress_bar = document.getElementById("dotnet_progress_bar");
      let jquery_progress_bar = document.getElementById("jquery_progress_bar");
      let sql_progress_bar = document.getElementById("sql_progress_bar");
      let sass_progress_bar = document.getElementById("sass_progress_bar");
      let wordpress_progress_bar = document.getElementById("wordpress_progress_bar");
      let react_progress_bar = document.getElementById("react_progress_bar");
      let drupal_progress_bar = document.getElementById("drupal_progress_bar");
      let kentico_progress_bar = document.getElementById("kentico_progress_bar");
      let laravel_progress_bar = document.getElementById("laravel_progress_bar");
      let java_progress_bar = document.getElementById("java_progress_bar");

      if (javascript_progress_bar){
        if (checkProgTechsVisible(javascript_progress_bar) == true){
          // We need to add the CSS class to start the animation for this div element
          if (!javascript_progress_bar.classList.contains("progress-bar-success-seventy")){
            javascript_progress_bar.classList.add("progress-bar-success-seventy");
          }
        }
      }

      if (php_progress_bar){
        if (checkProgTechsVisible(php_progress_bar) == true){
          // We need to add the CSS class to start the animation for this div element
          if (!php_progress_bar.classList.contains("progress-bar-success-seventy")){
            php_progress_bar.classList.add("progress-bar-success-seventy");
          }
        }
      }

      if (csharp_progress_bar){
        if (checkProgTechsVisible(csharp_progress_bar) == true){
          // We need to add the CSS class to start the animation for this div element
          if (!csharp_progress_bar.classList.contains("progress-bar-success-sixty")){
            csharp_progress_bar.classList.add("progress-bar-success-sixty");
          }
        }
      }

      if (dotnet_progress_bar){
        if (checkProgTechsVisible(dotnet_progress_bar) == true){
          // We need to add the CSS class to start the animation for this div element
          if (!dotnet_progress_bar.classList.contains("progress-bar-success-sixty")){
            dotnet_progress_bar.classList.add("progress-bar-success-sixty");
          }
        }
      }

      if (jquery_progress_bar){
        if (checkProgTechsVisible(jquery_progress_bar) == true){
          // We need to add the CSS class to start the animation for this div element
          if (!jquery_progress_bar.classList.contains("progress-bar-success-sixty")){
            jquery_progress_bar.classList.add("progress-bar-success-sixty");
          }
        }
      }

      if (sql_progress_bar){
        if (checkProgTechsVisible(sql_progress_bar) == true){
          // We need to add the CSS class to start the animation for this div element
          if (!sql_progress_bar.classList.contains("progress-bar-success-fifty")){
            sql_progress_bar.classList.add("progress-bar-success-fifty");
          }
        }
      }

      if (sass_progress_bar){
        if (checkProgTechsVisible(sass_progress_bar) == true){
          // We need to add the CSS class to start the animation for this div element
          if (!sass_progress_bar.classList.contains("progress-bar-success-fourty")){
            sass_progress_bar.classList.add("progress-bar-success-fourty");
          }
        }
      }

      if (wordpress_progress_bar){
        if (checkProgTechsVisible(wordpress_progress_bar) == true){
          // We need to add the CSS class to start the animation for this div element
          if (!wordpress_progress_bar.classList.contains("progress-bar-success-fourty")){
            wordpress_progress_bar.classList.add("progress-bar-success-fourty");
          }
        }
      }

      if (react_progress_bar){
        if (checkProgTechsVisible(react_progress_bar) == true){
          // We need to add the CSS class to start the animation for this div element
          if (!react_progress_bar.classList.contains("progress-bar-success-fourty")){
            react_progress_bar.classList.add("progress-bar-success-fourty");
          }
        }
      }

      if (drupal_progress_bar){
        if (checkProgTechsVisible(drupal_progress_bar) == true){
          // We need to add the CSS class to start the animation for this div element
          if (!drupal_progress_bar.classList.contains("progress-bar-success-twenty")){
            drupal_progress_bar.classList.add("progress-bar-success-twenty");
          }
        }
      }

      if (laravel_progress_bar){
        if (checkProgTechsVisible(laravel_progress_bar) == true){
          // We need to add the CSS class to start the animation for this div element
          if (!laravel_progress_bar.classList.contains("progress-bar-success-twenty")){
            laravel_progress_bar.classList.add("progress-bar-success-twenty");
          }
        }
      }

      if (kentico_progress_bar){
        if (checkProgTechsVisible(kentico_progress_bar) == true){
          // We need to add the CSS class to start the animation for this div element
          if (!kentico_progress_bar.classList.contains("progress-bar-success-twenty")){
            kentico_progress_bar.classList.add("progress-bar-success-twenty");
          }
        }
      }

      if (java_progress_bar){
        if (checkProgTechsVisible(java_progress_bar) == true){
          // We need to add the CSS class to start the animation for this div element
          if (!java_progress_bar.classList.contains("progress-bar-success-ten")){
            java_progress_bar.classList.add("progress-bar-success-ten");
          }
        }
      }

    }            

    return (    
      <div id="progess_techs_div" className="col-md-10 col-sm-12 progress-container">
      <Fade left>
        <div className="div_spacer_normal"></div>
        <h2>Technologies:</h2>
          <div className="progress_skill">
            <div className="col-sm-3 progress_skill_label">
              <h2>JavaScript</h2>
            </div>
            <div className="col-sm-9 progress-striped-main-container">
                <div className="progress progress-striped active">          
                    <div id="javascript_progress_bar" className="progress-bar" style={tech_progress}>
                      <div className="progress_percentage">70%</div>
                    </div>
                </div>
            </div>
          </div>
          <div className="progress_skill">
            <div className="col-sm-3 progress_skill_label">
              <h2>PHP</h2>
            </div>
            <div className="col-sm-9 progress-striped-main-container">
              <div className="progress progress-striped active">          
                  <div id="php_progress_bar" className="progress-bar" style={tech_progress}>
                    <div className="progress_percentage">70%</div>
                  </div>
              </div>
            </div>
          </div>        
          <div className="progress_skill">
            <div className="col-sm-3 progress_skill_label">
              <h2>C#</h2>
            </div>
              <div className="col-sm-9 progress-striped-main-container">
                <div className="progress progress-striped active">          
                    <div id="csharp_progress_bar" className="progress-bar" style={tech_progress}>
                      <div className="progress_percentage">60%</div>
                    </div>
                </div>
              </div>
          </div>
          <div className="progress_skill">
            <div className="col-sm-3 progress_skill_label">
              <h2>.NET</h2>
            </div>
              <div className="col-sm-9 progress-striped-main-container">
                <div className="progress progress-striped active">          
                    <div id="dotnet_progress_bar" className="progress-bar" style={tech_progress}>
                      <div className="progress_percentage">60%</div>
                    </div>
                </div>
              </div>
          </div>
          <div className="progress_skill">
            <div className="col-sm-3 progress_skill_label">
              <h2>jQuery</h2>
            </div>
              <div className="col-sm-9 progress-striped-main-container">
                <div className="progress progress-striped active">          
                    <div id="jquery_progress_bar" className="progress-bar" style={tech_progress}>
                      <div className="progress_percentage">60%</div>
                    </div>
                </div>
              </div>
          </div>
          <div className="progress_skill">
            <div className="col-sm-3 progress_skill_label">
              <h2>SQL</h2>
            </div>
              <div className="col-sm-9 progress-striped-main-container">
                <div className="progress progress-striped active">          
                    <div id="sql_progress_bar" className="progress-bar" style={tech_progress}>
                      <div className="progress_percentage">50%</div>
                    </div>
                </div>
              </div>
          </div>
          <div className="progress_skill">
            <div className="col-sm-3 progress_skill_label">
              <h2>SASS</h2>
            </div>
              <div className="col-sm-9 progress-striped-main-container">
                <div className="progress progress-striped active">          
                    <div id="sass_progress_bar" className="progress-bar" style={tech_progress}>
                      <div className="progress_percentage">40%</div>
                    </div>
                </div>
              </div>
          </div>
          <div className="progress_skill">
            <div className="col-sm-3 progress_skill_label">
              <h2>WordPress</h2>
            </div>
              <div className="col-sm-9 progress-striped-main-container">
                <div className="progress progress-striped active">          
                    <div id="wordpress_progress_bar" className="progress-bar" style={tech_progress}>
                      <div className="progress_percentage">40%</div>
                    </div>
                </div>
              </div>
          </div>
          <div className="progress_skill">
            <div className="col-sm-3 progress_skill_label">
              <h2>React</h2>
            </div>
              <div className="col-sm-9 progress-striped-main-container">
                <div className="progress progress-striped active">          
                    <div id="react_progress_bar" className="progress-bar" style={tech_progress}>
                      <div className="progress_percentage">40%</div>
                    </div>
                </div>
              </div>
          </div>
          <div className="progress_skill">
            <div className="col-sm-3 progress_skill_label">
              <h2>Drupal</h2>
            </div>
              <div className="col-sm-9 progress-striped-main-container">
                <div className="progress progress-striped active">          
                    <div id="drupal_progress_bar" className="progress-bar" style={tech_progress}>
                      <div className="progress_percentage">20%</div>
                    </div>
                </div>
              </div>
          </div>        
          <div className="progress_skill">
            <div className="col-sm-3 progress_skill_label">
              <h2>Laravel</h2>
            </div>
              <div className="col-sm-9 progress-striped-main-container">
                <div className="progress progress-striped active">          
                    <div id="laravel_progress_bar" className="progress-bar" style={tech_progress}>
                      <div className="progress_percentage">20%</div>
                    </div>
                </div>
              </div>
          </div>
          <div className="progress_skill">
            <div className="col-sm-3 progress_skill_label">
              <h2>Kentico</h2>
            </div>
              <div className="col-sm-9 progress-striped-main-container">
                <div className="progress progress-striped active">          
                    <div id="kentico_progress_bar" className="progress-bar" style={tech_progress}>
                      <div className="progress_percentage">20%</div>
                    </div>
                </div>
              </div>
          </div>
          <div className="progress_skill">
            <div className="col-sm-3 progress_skill_label">
              <h2>Java</h2>
            </div>
              <div className="col-sm-9 progress-striped-main-container">
                <div className="progress progress-striped active">          
                    <div id="java_progress_bar" className="progress-bar" style={tech_progress}>
                      <div className="progress_percentage">10%</div>
                    </div>
                </div>
              </div>
          </div>
        </Fade>
      </div>    
    );
  }
}

class App extends Component {
  
    render() {      

      return (
      <div>

      <NavComponent />      

      <div className="clearfix"></div>   
              
      <Route path="/" exact component={Home}/>          
      </div>
      );
    }
}

export default App;
